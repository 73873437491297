<template>
  <div id="header-component">
    <div class="header-desktop" :class="($store.getters.userIsAdmin) ? 'header-desktop-admin' : ''">
      <div class="d-flex p-3" :class="($store.getters.userIsAdmin || $store.getters.userIsMarketing ) ? 'justify-content-between' : ''">
        {{ /* ICONS MENU */ }}
        <b-icon  v-if="this.$store.getters.userIsAdmin || $store.getters.userIsMarketing" class="ml-2 mt-2" icon="list" font-scale="2.5" variant="primary" v-on:click="clickEventBurger(false)"></b-icon>

        {{ /* HEADER ADMIN */ }}
        <div class="col-sidebar" v-if="$store.getters.userIsAdmin || $store.getters.userIsMarketing ">
          <div class="d-flex">
            <div class="d-flex flex-column justify-content-center">
              <i>Bienvenue, <u>{{ this.$store.getters.getCurrentUser.firstname }} {{ this.$store.getters.getCurrentUser.lastname }}</u></i>
            </div>

            <b-dropdown id="dropdown-right" right text="Right align" variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <div class="icon-user-header ml-2">
                  <b-icon icon="person" font-scale="1.5" variant="primary"></b-icon>
                </div>
              </template>
              <b-dropdown-item :href="'/user/profile/' + this.$store.getters.getCurrentUser.id">Ma page profil</b-dropdown-item>
              <b-dropdown-item href="/logout">Se déconnecter</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="col-8 offset-2 mt-3" v-else>
          {{ /* HEADER SHOP */ }}
          <div class="d-flex justify-content-between">
            <div>
              <router-link class="" :to="{ name: 'sale', params: {id: this.$store.getters.getCurrentUser.shop.id }}">
                <img :src="require('../../../public/images/'+ ChannelModule.channel.code +'/logo.svg')" alt="Logo" width="200">
              </router-link>
            </div>
            <div>
              <div class="d-flex">
                <div class="mt-3">
                  <i>Bienvenue, <u>{{ this.$store.getters.getCurrentUser.firstname }} {{ this.$store.getters.getCurrentUser.lastname }}</u></i>
                </div>

                <b-dropdown id="dropdown-right" right text="Right align" variant="link" toggle-class="text-decoration-none" no-caret>
                  <template #button-content>
                    <div class="icon-user-header ml-2">
                      <b-icon icon="person" font-scale="1.5" variant="primary"></b-icon>
                    </div>
                  </template>
                  <b-dropdown-item :href="'/user/profile/' + this.$store.getters.getCurrentUser.id">Ma page profil</b-dropdown-item>
                  <b-dropdown-item href="/logout">Se déconnecter</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {{ /* HEADER MOBILE */ }}
    <div v-if="this.$store.getters.userIsAdmin || $store.getters.userIsMarketing" class="header-mobile-admin p-3 app-box-shadow">
      <div class="d-flex justify-content-between">
        <div class="mt-2">
          <router-link class="" :to="{ name: 'dashboard' }">
            <img class="w-100" :src="require('../../../public/images/'+ ChannelModule.channel.code +'/logo-white.svg')" alt="Logo">
          </router-link>
        </div>
        <div class="d-flex justify-content-center">
          <b-dropdown id="dropdown-right" right text="Right align" variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <div class="icon-user-header">
                <b-icon icon="person" font-scale="2" variant="light"></b-icon>
              </div>
            </template>
            <b-dropdown-item :href="'/user/profile/' + this.$store.getters.getCurrentUser.id">Ma page profil</b-dropdown-item>
            <b-dropdown-item href="/logout">Se déconnecter</b-dropdown-item>
          </b-dropdown>
          <b-icon class="mt-2" icon="list" font-scale="2.5" variant="white" v-on:click="clickEventBurger(true)"></b-icon>
        </div>
      </div>
    </div>
    <div v-else class="header-mobile-shop">
      {{ /* HEADER SHOP */ }}
      <div class="d-flex justify-content-between">
        <div class="mt-4 ml-2">
          <img :src="require('../../../public/images/'+ ChannelModule.channel.code +'/logo.svg')" alt="Logo">
        </div>
        <div class="mt-4 mr-2">
          <b-dropdown id="dropdown-right" right text="Right align" variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <div class="icon-user-header ml-2">
                <b-icon icon="person" font-scale="1.5" variant="primary"></b-icon>
              </div>
            </template>
            <b-dropdown-item :href="'/user/profile/' + this.$store.getters.getCurrentUser.id">Ma page profil</b-dropdown-item>
            <b-dropdown-item href="/logout">Se déconnecter</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    showSidebar: {
      type: Boolean
    },
  },
  methods: {
    clickEventBurger(isMobile) {
      if(isMobile)
      {
        let boolShowSidebar = this.showSidebar
        boolShowSidebar = boolShowSidebar === false

        this.$emit('click-event-burger', { showSidebar : boolShowSidebar, isMobile : isMobile })
      }
      else {
        let boolShowSidebar = !this.showSidebar

        this.$emit('click-event-burger', { showSidebar : boolShowSidebar, isMobile : isMobile })
      }

    }
  },
  created() {
  },
  computed: {
    ...mapState(['ChannelModule']),
    ...mapState(['AuthModule']),
    ...mapState(['UserModule']),
  },
}
</script>

<style scoped>

</style>
